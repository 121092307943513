import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormControlLabel, Radio, RadioGroup, Checkbox, TextField } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import { ImCheckmark } from "react-icons/im";
import feedback from "../assets/img/feedback.jpg";
import logo from "../assets/img/logo.png";
import axios from "axios";
import happy from '../assets/img/emotions/happy.png'
import ok from '../assets/img/emotions/ok.png'
import sad from '../assets/img/emotions/sad.png'
import deadline from '../assets/img/deadline.png'
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from 'moment';


const FeedbackPreview = () => {
    const [timeExp, setTimeExp] = useState(true)
    const [submitBtnLoad, setSubmitBtnLoad] = useState(false)
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [thumb, setThumb] = useState("")
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [responses, setSelectedResponses] = useState({});
    const [dataStatus, setDataStatus] = useState(false);
    const configData = useSelector((state) => state.configSlice);

    const handleSubmit = (values) => {
        if (Object.keys(responses).length !== questions.length) {
            console.log("Please answer all questions");
            return;
        }
        createResponse();
    };

    const handleClose = () => setShow(false);
    const handleTextChange = (event, questionId) => {
        setSelectedResponses((prev) => ({ ...prev, [questionId]: event.target.value }));
    };
    const { uuid, feedbackId } = useParams();
    const getFeedBackData = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/feedback/getFeedback/${feedbackId}`
            );
            setTitle(data.data.name);
            setDescription(data.data.description);
            console.log('expiryTimeCFRT')
            console.log(data.data.expiryTime)
            setTimeExp(data.data.expiryTime)
            setThumb(data.data.thumbnail);
            setQuestions(data.data.data || []);

        } catch (error) {

        }
    };
    useEffect(() => {
        getFeedBackData();
    }, [uuid, feedbackId, configData]);

    const createResponse = async () => {
        try {
            let payload = {
                responses,
                applicationId: configData.config.applicationId,
              };
            const data = await axios.post(
                `${configData.config.UNO_URL}/api/feedback/submitFeedBack/${uuid}/${feedbackId}`,
                payload
            );
            if (data.data.status === false) {
                setDataStatus(true);
            }

            if (data.data.status === true) {
                setSubmitBtnLoad(true);
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                    setSubmitBtnLoad(false);
                }, 1000);
            }

        } catch (error) {
            console.log(error.message);
        }
    };

    const isFormExpired = moment().isBefore(timeExp);
    console.log(isFormExpired)
    
    return (
        <>
            <section className="preview pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="border">
                                {thumb ? <img
                                    src={thumb}
                                    className="img-fluid"
                                    style={{ height: "100px", width: "100%", objectFit: "cover" }}
                                    alt=""
                                />
                                    : <img
                                        src={feedback}
                                        className="img-fluid"
                                        style={{ height: "100px", width: "100%", objectFit: "cover" }}
                                        alt=""
                                    />
                                }

                                <div className="p-4 bg-light">
                                    {isFormExpired && (
                                        <>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5>{title} </h5>
                                                    <p className="m-0">{description}</p>
                                                </div>
                                            </div>

                                            <h4 className="mt-4">Questions</h4>
                                            {questions.length > 0 &&
                                                questions.map((item, index) => {
                                                    return (
                                                        <div className="card mt-3" key={`question_${index}`}>
                                                            <div className="card-body">
                                                                <h6>{`Q${index + 1}. ${item.name}`}  </h6>
                                                                {item.type === "TEXT" ? (
                                                                    <TextField
                                                                        id={`question_${index}`}
                                                                        label="Answer"
                                                                        variant="outlined"
                                                                        multiline
                                                                        rows={2}
                                                                        maxRows={4}
                                                                        fullWidth
                                                                        onChange={(event) => handleTextChange(event, item.id)}
                                                                        value={responses[item.id] || ""}
                                                                    />
                                                                ) : (
                                                                    <RadioGroup
                                                                        value={responses[item.id] || ""}
                                                                        onChange={(event) =>
                                                                            setSelectedResponses((prev) => ({
                                                                                ...prev,
                                                                                [item.id]: event.target.value,
                                                                            }))
                                                                        }>
                                                                        {item.options.map((option, optionIndex) => (
                                                                            <div key={`option_${index}_${optionIndex}`} className="d-flex align-items-center">
                                                                                <FormControlLabel
                                                                                    value={option.id}
                                                                                    control={<Radio />}
                                                                                    label={
                                                                                        <>
                                                                                            {option.name === 'Happy' &&
                                                                                                <OverlayTrigger key="top" placement="top" overlay={<Tooltip id="tooltip-bottom">Happy</Tooltip>} >
                                                                                                    <img src={happy} style={{ width: '27px' }} alt="happy" />
                                                                                                </OverlayTrigger>
                                                                                            }
                                                                                            {option.name === 'Ok' &&
                                                                                                <OverlayTrigger key="top" placement="top" overlay={<Tooltip id="tooltip-bottom">Ok</Tooltip>} >
                                                                                                    <img src={ok} style={{ width: '27px' }} alt="ok" />
                                                                                                </OverlayTrigger>
                                                                                            }
                                                                                            {option.name === 'Sad' &&
                                                                                                <OverlayTrigger key="top" placement="top" overlay={<Tooltip id="tooltip-bottom">Sad</Tooltip>} >
                                                                                                    <img src={sad} style={{ width: '27px' }} alt="sad" />
                                                                                                </OverlayTrigger>
                                                                                            }
                                                                                            {option.name !== 'Happy' && option.name !== 'Ok' && option.name !== 'Sad' && option.name}
                                                                                        </>
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </RadioGroup>

                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                            {isSubmitClicked && Object.keys(responses).length !== questions.length && (
                                                <div className="text-danger mt-3"> Please answer all questions before submitting. </div>
                                            )}

                                            {dataStatus === true && (
                                                <div className="text-danger">
                                                    You already submitted this feedback
                                                </div>
                                            )}

                                            {questions.length > 0 &&
                                                <div className="d-flex justify-content-between align-items-center mt-4">
                                                    <button className="btn btn-danger" onClick={() => { handleSubmit(); setIsSubmitClicked(true); }} disabled={dataStatus}>
                                                        {submitBtnLoad ? (
                                                            <> <div className="d-flex align-items-center gap-1">
                                                                <Spinner size="sm" /> Submitting...
                                                            </div> </>
                                                        ) : 'Submit'}
                                                    </button>
                                                    <div className="col-md-6">
                                                        <div className="d-flex justify-content-end">
                                                            <a href="">Clear form</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>)
                                    }
                                    {!isFormExpired && (
                                        <div className="card expiredt">
                                            <div className="card-body">
                                                <h4>
                                                    <strong className="d-flex gap-2">
                                                        <OverlayTrigger key="top" placement="top" overlay={<Tooltip id="tooltip-bottom">This feedback form has been expired</Tooltip>} >
                                                            <img src={deadline} style={{ width: '28px' }} alt="Expired" />
                                                        </OverlayTrigger>
                                                        {title}
                                                    </strong>
                                                </h4>
                                                <p className="m-0">The form online feedback management is no longer accepting responses.</p>
                                                <p className="m-0">Try contacting the owner of the form if you think this is a mistake.</p>
                                            </div>
                                        </div>
                                    )
                                    }
                                    <div className="text-center py-5 pb-4">
                                        <p className="my-2">This form created inside {"Simulanis"}</p>
                                        <img src={logo} style={{ width: "130px" }} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal size="sm" show={show} onHide={handleClose}>
                <Modal.Body className="p-0">
                    <div className="bg-success text-center p-4 py-4 text-white rounded-top">
                        <ImCheckmark style={{ fontSize: '3rem' }} />
                    </div>
                    <div className="p-3 text-center">
                        <h5> <strong>Thank You</strong>{' '} </h5>
                        <p>For your feedback it's greatly appriciated</p>
                        <button className="btn btn-light w-25" onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default FeedbackPreview;