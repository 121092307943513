import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: {}
};

const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    saveConfig: (state, action) => {
      state.config = action.payload.data;
      // state.config = { ...action.payload.data, UNO_URL: "http://localhost:3003"};
    },
    removeConfig: (state, action) => {
      state.config = {};
    },
  },
});

export const { saveConfig, removeConfig } = configSlice.actions;
export default configSlice.reducer;