import React from 'react'
import pagenotfound from '../assets/img/pagenotfound.svg'
const PageNotFound = () => {
    return (
        <>
            <div className="pagenotfound">
                <div className="container">
                    <div className="row pgnrt align-iems-center justify-content-center">
                        <div className="col-md-9">
                            <div className="row justify-content-center mb-1">
                                <div className="col-md-10">
                                    <img src={pagenotfound} alt="Page not found" className='img-fluid' />
                                </div>
                            </div>
                            <p className='text-center mt-4'>You can find more information about Simulanis on their official website at  <a href="https://simulanis.com/" target='_blank'>www.simulanis.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound
