import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import FeedbackPreview from "./FeedbackPreview/FeedbackPreview";
import { UNO_URL, applicationId } from "./env";
import { useDispatch, useSelector } from "react-redux";
import Lottie from 'lottie-react';
import animationData from './FullScreenLoader/loading.json';

import { saveConfig } from "../src/store/configSlice"
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "react-loading-skeleton/dist/skeleton.css";
import PageNotFound from "./FeedbackPreview/PageNotFound";

const App = () => {

  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch();

  const getConfigData = async () => {
    const { data } = await axios.get(`${UNO_URL}/api/application/configuration/${applicationId}`);
    const config = { ...data.data.data };
    setLoader(true)
    dispatch(saveConfig({ data: config }));
  };


  useEffect(() => {
    getConfigData();
  }, []);

  return (
    <>
      {!loader ?
        <Lottie
          animationData={animationData}
          loop
          autoplay
          speed={1.5}
          style={{
            width: '250px',
            height: '250px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
        :
        <Router>
          <Routes>
            <Route path="/:uuid/:feedbackId" element={<FeedbackPreview />} />
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      }
    </>
  );
};

export default App;
